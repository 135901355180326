import React from "react";
import styled from "styled-components";
import Link from "../Link";
import * as Widget from "../Widget";
import * as Icon from "../Icon";

export default function NavbarFactory({
  coverContent = true,
  background = "rgba(0,0,0,0)",
  altBackground = "#0079ff",
  textColor = "white",
  textShadow = "2px 2px #ccc",
  height = 60
} = {}) {
  const ScreenSizeBoundry = 800;
  const navItems = [
    { children: "關於我們", url: "/about-us" },
    { children: "青田悅誌", url: "/blog" },
    { children: "最新消息", url: "/news" },
    { children: "預約參觀", url: "/reservation" }
  ];

  const fixedNavItems = [
    { children: "專業照護", url: "/medical-team" },
    { children: "環境介紹", url: "/environment" },
    { children: "膳食料理", url: "/cuisine" },
    { children: "青田電商", url: "/" }
  ];

  class Navbar extends React.Component {
    state = {
      openMenu: false
    };

    render() {
      let { passBreakpoint } = this.props;

      return (
        <Style.Wrapper passBreakpoint={passBreakpoint}>
          <Style.Content>
            <Link to="/" className="site-title">
              <img src="/images/logo.png" />
            </Link>
            {this._smallScreenNavBar()}
            {this._largeScreenNavBar()}
            {this._overlayScreenNavBar()}
          </Style.Content>
        </Style.Wrapper>
      );
    }

    _overlayScreenNavBar() {
      return (
        <Style.OverlayScreenWrapper>
          <Style.OverlayScreenContent>
            {fixedNavItems.map(({ children, url }, idx) => (
              <Link to={url} key={"overlay" + idx} className="nav-item">
                {children}
              </Link>
            ))}
          </Style.OverlayScreenContent>
        </Style.OverlayScreenWrapper>
      );
    }

    _smallScreenNavBar() {
      const toggleMenuState = () =>
        this.setState({ openMenu: !this.state.openMenu });
      return (
        <Style.SmallScreenWrapper Breakpoint={ScreenSizeBoundry}>
          <Widget.IconButton onClick={toggleMenuState}>
            <Icon.Apps color="#71C6D0" />
          </Widget.IconButton>

          <Style.SmallScreenMenu
            open={this.state.openMenu}
            onClick={toggleMenuState}
          >
            <div className="container">
              {[
                { children: <Icon.Home color="white" />, url: "/" },
                ...navItems
              ].map(({ children, url }, idx) => (
                <Link to={url} key={idx} className="nav-item">
                  {children}
                </Link>
              ))}
            </div>
          </Style.SmallScreenMenu>
        </Style.SmallScreenWrapper>
      );
    }

    _largeScreenNavBar() {
      return (
        <Style.LargeScreenWrapper Breakpoint={ScreenSizeBoundry}>
          {navItems.map(({ children, url }, idx) => (
            <Link to={url} key={idx} className="nav-item">
              {children}
            </Link>
          ))}
        </Style.LargeScreenWrapper>
      );
    }
  }

  const Style = {
    OverlayScreenWrapper: styled.div`
      position: fixed;
      top: 100px;
      left: 0;

      padding: 12px 20px;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      width: 100vw;
      height: 40px;

      @media screen and (max-width: 600px) {
        padding: 6px 20px;
        height: 30px;
      }

      @media screen and (max-width: 414px) {
        padding: 5px;
        height: 25px;
      }
    `,

    OverlayScreenContent: styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1440px;
      height: 100%;
      overflow: scroll;

      & > .nav-item {
        min-width: 178px;
        text-decoration: none;
        text-align: center;
        font-size: 14px;
        color: #101d1f;
        letter-spacing: 1.6px;

        @media screen and (max-width: 600px) {
          min-width: 120px;
          font-size: 12px;
        }
      }

      @media screen and (max-width: 414px) {
        justify-content: flex-start;
      }
    `,

    Wrapper: styled.div`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 0px 20px;
      z-index: 10;
      background: white;
      transition: background 200ms ease-in-out;
    `,

    Content: styled.div`
      margin: 0 auto;
      max-width: 1440px;
      height: 100px;
      display: flex;
      align-items: center;

      & > .site-title {
        color: ${textColor};
        text-shadow: ${textShadow};
        text-decoration: none;
        font-size: 30px;
      }

      & img {
        width: 270px;

        @media screen and (max-width: 600px) {
          width: 220px;
        }
      }
    `,

    LargeScreenWrapper: styled.div`
      @media screen and (max-width: ${props => props.Breakpoint || 768}px) {
        display: none;
      }

      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      & > .nav-item {
        margin-right: 15px;
        font-size: 18px;
        color: #101d1f;
        text-decoration: none;
        display: flex;
        align-items: center;
      }

      & > .nav-item :last-child {
        margin-right: 0px;
      }
    `,

    SmallScreenWrapper: styled.div`
      @media screen and (min-width: ${props => props.Breakpoint || 768}px) {
        display: none;
      }

      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    `,

    SmallScreenMenu: styled.div`
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: ${props => (props.open ? "0" : "-100vw")};
      background-color: ${props =>
        props.open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
      transition: background-color 250ms linear,
        left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
      z-index: 5;

      & > .container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-top: 30px;

        & > .nav-item {
          color: ${textColor};
          height: 50px;
          text-decoration: none;
        }
      }
    `
  };

  return Navbar;
}
