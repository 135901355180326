import React, { Component } from "react";
import styled from "styled-components";
import LangSwitcher from "../LangSwitcher";
import Link from "../Link";

const FooterFactory = config => {
  class Footer extends Component {
    render() {
      let { extraStyle = "" } = this.props;

      return (
        <Wrapper>
          <Container extraStyle={extraStyle}>
            <Content main>
              <div className="icons">
                <img className="icon" src="/images/icon01.png" />
                <img className="icon" src="/images/icon02.png" />
                <img className="icon" src="/images/icon03.png" />
                <img className="icon" src="/images/icon04.png" />
              </div>
            </Content>
            <Content>
              <div className="company-info">青田悅產後護理之家</div>
              <div className="company-info">電話：02-1234-5678</div>
              <div className="company-info">
                地址：臺北市中山區南京東路2段160號6樓
              </div>
            </Content>
          </Container>

          <Text>
            Copyright © 2019 青田悅產後護理之家 Limited. All rights reserved.
          </Text>
        </Wrapper>
      );
    }
  }

  let Wrapper = styled.div``;

  let Container = styled.div`
    background-color: #84c4ce;
    width: 100vw;
    padding: 20px;
    color: #505050;
    display: flex;
    align-items: center;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
    ${props => props.extraStyle};
  `;

  let Content = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: ${props => (props.main ? 1 : 0)};
    min-width: 200px;

    & > .company-info {
      line-height: 30px;
      letter-spacing: 1.4px;
      text-align: right;
      font-size: 13px;
      color: #101d1f;
    }

    & > .title {
      font-size: 15px;
      margin-bottom: 10px;
    }

    & > .menu {
      flex-grow: 1;
    }

    & > .icons {
      display: flex;

      & > img {
        margin-right: 20px;
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 500px) {
      margin-top: 20px;
      width: 100%;
    }
  `;

  let Text = styled.div`
    padding: 10px;
    width: 100%;
    background: #b2dbe1;
    letter-spacing: 1.4px;
    text-align: center;
    font-size: 13px;
    color: rgba(16, 29, 31, 0.6);
  `;

  return Footer;
};

export default FooterFactory;
